.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background: #2A2240!important;
  font-family: 'Montserrat'!important;
}
/* navbar */
.desktopnavsec{
  height: 100px;
  background: #413367;
}
.navsec{
  margin-top:38px;
}
.desktopNav{
  list-style-type: none,
}

.desktopNav li{
  display: inline;
  padding:15px;

}

.desktopNav li a{
 color:#fff;
 font-size:17px;

}
.desktopNavDiv{
  float:right;
  margin-right: 45px;
}

.desktopLocation{
  background-color: #574a78;
  height: 36px;
  border-radius: 6px;
  width: 52%;
  margin-left: 7%;
}

.desktoplocationtext{
  color: #fff;
  padding:5px;
}

.logoarea{
  margin-left: 45px;
  width:31%;
}

.searchiconposition{
  position: absolute;
  left: 25px;
  top: 10px;
  color: #fff;
}


@media only screen and (max-width: 768px) {
  .deskTopNav{
    display:none;
  }
  .desktopfooter{
    display:none;
  }

  .refercodedesktop{
    display:none;
  }

  .desktopOfflineCategorySlider{
    display:none;
  }

  .cardandrewardSection{
    display:none;
  }

  .desktopHotdealsSlider{
    display:none;
  }

  .desktoponlineCategorySlider{
    display:none;
  }

  .desktopHotdealsSlider{
    display:none;
  }

  .desktopnewlyadd{
    display:none;
  }

  .desktopviewnewlyadd{
    display:none;
  }


  .mobileViewCustomPageHeader {
    background: #413367;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) {

  .MobileNavbar{
    display:none;
  }

  .mobilefooterarea{
    display:none;
  }

  .mobileOfflineCategorySlider{
    display:none;
  }

  .mobileHotdealsSlider{
    display:none;
  }

  .mobilecardsection{
    display:none;
  }

  /* body{
    width: 35%!important;
    margin: auto!important;
  } */

  .mobileviewNav{
    position: relative!important;
  }

  .mobileViewCustomPageHeader {
      background: #413367!important;
      position: static!important;
      top: 0!important;
      z-index: 999!important;
      height: 60px!important;
  }
}

.MobileNavbar{
  height:43px;
  background: #413367;
}

.mobilenavsec{
  margin-top:9px;
}

.mobilenavlocationtext{
  /* border-bottom: 1px dotted; */
  color:#fff;
  font-size:9px;
}

.mobilenotificationicon{
  float:right;
}

.mnicon{
  color:#fff;
}

.mlicon{
  font-size: 17px;
}

.footerarea{
  background: #413367;
  padding-top: 54px;
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  z-index:999;
}

.footerlogoarea{
  margin-left:45px;
}

.footerLink li{
  list-style-type:none;
  color:#fff;
}

.footerleft{
  margin-top: 30px;
}

.footerlogoarea p{
  color: #fff;
}
.mobilefooterarea{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
.bar {
  /* width: 340px; */
  height: 70px;
  background: #413367;
  box-shadow: 0 10px 40px rgba(51, 51, 51, 0.4);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px;
}

.els-wrap {
  display: flex;
  align-items: center;
  width: 33px;
  background-size: 1200px;
  background-position: 100% 50%;
  color: #333333;
  padding: 0 7px;
  border-radius: 100px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  transition: width 200ms, padding 200ms, border-radius 300ms, background-position 900ms;
  transition-timing-function: ease-in-out;
}
.els-wrap:hover {
  background-position: 0 50%;
  padding: 3px 13px;
  border-radius: 50px;
}
.els-wrap:hover .icon {
  font-size: 27px;
  margin-right: 10px;
}
.els-wrap:hover .label {
  opacity: 1;
}
.els-wrap:focus {
  background-position: 0 50%;
  padding: 3px 13px;
  border-radius: 50px;
}
.els-wrap:focus .icon {
  font-size: 27px;
  margin-right: 10px;
}
.els-wrap:focus .label {
  opacity: 1;
}
.els-wrap .icon {
  font-size:21px;
  margin-right: 25px;
  position: relative;
  z-index: 2;
  transition: font-size 250ms, margin-right 200ms;
  transition-timing-function: ease-out;
}
.els-wrap .label {
  font-weight: 600;
  letter-spacing: 0.15em;
  opacity: 0;
  position: relative;
  z-index: 2;
  font-size: 11px;
  transition: opacity 400ms;
  transition-delay: 130ms;
  transition-timing-function: ease-out;
  margin-top: 4px;
  margin-bottom:0px;
}

.el-0 {
  background-image: radial-gradient(circle at right, #fff, rgb(216,216,255));
}

.el-1 {
  background-image: radial-gradient(circle at right, #fff, rgb(216,216,255));
}

.el-2 {
  background-image: radial-gradient(circle at right, #fff, rgb(255,216,216));
}

.el-3 {
  background-image: radial-gradient(circle at right, #fff, rgb(255,236,216));
}

.el-4 {
  background-image: radial-gradient(circle at right, #fff, rgb(255,255,216));
}

.el-0:hover {
  width: 99.5px;
}

.el-0:focus {
  width: 99.5px;
}

.el-1:hover {
  width: 99.5px;
}

.el-1:focus {
  width: 99.5px;
}

.el-2:hover {
  width: 99.5px;
}

.el-2:focus {
  width: 99.5px;
}

.el-3:hover {
  width:99.5px;
}

.el-3:focus {
  width:99.5px;
}

.el-4:hover {
  width: 99.5px;
}

.el-4:focus {
  width: 99.5px;
}

.cardandrewardSection{
  margin-top:35px;
  margin-bottom:35px;
}

.cardUserName{
  position: absolute;
  top: 30%;
  left: 9%;
  color: #fff;
}

.cardUserName p{
  margin-bottom:0px;
}

.GoOutTitle{
  margin-left: 7px;
  font-size: 15px;
  font-weight: 700;
  color:#fff;
}

.hotpicimage{
  width:100%;
  /* margin: 20px; */
}

.popularbrandimage{
  border-radius: 10px;
  
}
.popularbrnaddiv{
  position: relative;
  top:0;
  bottom:0;
  margin:5px;
}
.slick-dots{
  color:#ffc107;
}
.categoryimage{

  width:94%;
}
.popularbrandimageoffer{
  position: absolute;
  top: 0%;
  color: #fff;
  font-size: 11px;
  left: 0px;
  /* background: #ffc107; */
  height: 19px;
  /* padding: 2px; */
  border-radius: 1px;
}

.newlyAddlogo{
  position: absolute;
  top: 9%;
  color: #fff;
  font-size: 11px;
  left: 0px;
  /* background: #ffc107; */
  height: 19px;
  /* padding: 2px; */
  border-radius: 1px;
}

.newlyaddlogoarea{
  width: 100%;
  height: 37px;
  margin-left: 6px;
  border-radius: 10px;
}

.newlyAddOfferTag{
  position: absolute;
  top: 50%;
  margin-left: 8px;
}
.popularbrandimageDistance {
  position: absolute;
  top: 0%;
  color: #fff;
  font-size: 10px;
  left: 58%;
  background: black;
  opacity: 0.5;
  height: 18px;
  padding: 2px;
  margin-right: 0px;
  border-radius: 1px;
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 10px;
}

.newlyadditem{
  position: relative;
  top: 0;
  left: 0;
}
.newlyaddlogo{
  position: absolute;
  top: 7%;
  left: 5%;
}

.newlyaddlogoimage{
  width: 35%;
  border-radius: 6px;
}

.newlyaddDistance{
  position: absolute;
  top: 7%;
  left: 63%;
  color: #fff;
  background: black;
  opacity: 0.5;
  height: 19px;
  padding: 2px;
  font-size: 11px;
  border-radius: 1px;
}


.mobileViewCustomPageHeader{
  background: #413367;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 60px;
}

.MobileViewReward{
  margin-top: 81px;
}

.pageTitle{
  margin-top: 17px;
}

.profilepic{
  width: 82%;
  border-radius: 50%;
}

.profiledetail{
    background: #413367;
    border-radius: 10px;
    padding: 10px;
}

.pdetail p{
  margin-bottom:0px;
}

.svaingsicon{
  width: 35%;
}

.profileLink{
  color:#fff;
  font-weight:500;
  margin-bottom:0;
}

.profilelinkDescription{
  color:#fff;
  margin-bottom: 5px;
  font-size: 12px;
}

.profilelinkicon{
  color: #fff;
  border: 1px solid #fff;
  height: 20px;
  width: 20px;
  padding: 1.5px;
  padding-left: 7px;
  border-radius: 50%;
}

.picondiv{
  margin-top:11px;
}

.sresultheader{
  padding:15px;
  border-bottom:1px solid gray;
}

.sresultheader p{
  margin-bottom: 0px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.membershipDetail{
  background: #413367;
  padding: 25px;
  border-radius: 13px;
}

.membershipDetailEliment p{
  color: #fff;
  font-size: 12px;
}

.membershipDetailknowmore{
  background: #fff;
  padding: 25px;
  border-radius: 13px;
}

.membershipDetailknowmoretitle{
  margin-bottom: 15px;
  border-bottom: 1px solid #e7e1e1;
}

.membershipDetailknowmoretitlediv{
  margin-bottom: 10px;
}

.membershipDetailknowmoretitlediv a{
  color:black;
  text-decoration: none;
  font-size: 13px!important;
}

.membershipdescription{
  font-size:12px;
  text-align: justify;
}

.browseplans{
  margin-bottom: 150px;
  background: #413367;
  position: fixed;
  bottom: 0;
  top:80%;
  width:100%;
}

.browseplanbuttondesign{
  background: #c356d1;
  border: #c356d1;
  color: #fff;
  width:100%;
  padding: 9px;
  border-radius: 6px;
  text-transform:uppercase;
}

.backgroundbtn{
  margin-bottom: 150px;
  background: #2A2240;
  height: 100px;
  padding-top: 11px;
}

.browseplanss{
  /* margin-bottom: 150px; */
  background: #413367;
  position: fixed;
  bottom: 0;
  top:80%;
  width:100%;
}

.browseplanbuttondesigns{
  background: #c356d1;
  border: #c356d1;
  color: #fff;
  width:100%;
  padding: 9px;
  border-radius: 6px;
}

.listview{
  margin-top:50px;
}

.listOutletLogo{
  margin-top: 125px;
  margin-bottom: 10px;
}

.listOutletDetail{
  background-color:#fff;
}

.listOutletDetail{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.listViewOutletimage{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
}

.offertagdiv{
  
  position: absolute;
  top: 1px;
  left: 14%;

}

.offertagdiv p{
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  color:#fff;
}

.outlettitle p{
  font-size: 17px;
  font-weight: 600;
  margin: 0px;
  margin-top:10px
}

.outletlistaddress p{
  font-size: 13px;
  margin-top: 4px;
  margin-bottom: 0px;
  margin-bottom: 10px;
}

.offeringlistview p{
  font-size: 13px;
  margin-bottom: 5px;
}

.offeringlistview p span{
  font-size: 11px;
  margin-bottom: 5px;
}

.rattingListview {
  position: absolute;
  top: 16px;
  left: 38px;
  font-size: 11px;
}

.offeringlistview{
  margin-bottom: 12px;
}

.listviewtag{
  margin-top: 10px;
  margin-bottom: 10px;
}

.listviewdistance{
  background: black;
  margin-bottom: 0px;
  height: 25px;
  opacity: 0.5;
  color:#fff;
  margin-right: -24px;
  margin-top: 19px;
  border-radius: 5px;
}

.listviewdistance p{
  font-size: 13px;
  padding: 3px;
}

.outletview{
  margin-bottom: 20px;
}

.wrap {
  display: flex;
  margin-bottom:5px;
  }
    
.wrap>* {
  flex: 1;
}
    
.imagebox {
  position: relative;
}
    
.textbox {
   margin-left: -99px;
}

.listOutletDetailsLogo{
  margin-top:158px;
  margin-bottom:41px;
}

.offlineOutletDetail{
  border-radius:10px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.offlineoutletPhotos{
  background: black;
  margin-bottom: 0px;
  height: 25px;
  opacity: 0.5;
  color: #fff;
  margin-right: -23px;
  margin-top: 53px;
}

.outletDetailFavourite{
  background: black;
  width: 29px;
  opacity: 0.5;
  margin-right: 7px;
  margin-top: 10px;
  border-radius: 50%;
}

.outletDetailtitle p {
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
  margin-top: 10px;
}

.outletDetailaddress p {
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
  font-weight: 400;
}

.outletdetaildistance{
  background: #574a78;
  padding: 4px;
  border-radius: 5px;
  margin-left: 10px;
}

.backoutletdetailsicon{
  background: black;
  font-size: 32px;
  margin-left: 19px;
  margin-top: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0.5;
}

.backoutletdetailsicon i{
  position: absolute;
  margin-left: 7px;
  color: #fff;
}

.offerdiv{
  background:#fff;
  border-radius: 9px;
  margin-top:20px;
}

.offlineofferName{
  border: 3px dotted;
  padding:5px;
}

.offlineofferName p{
  margin:0px;
}

.offlineOutletOfferDescriptionDetails{
  border-top:1px solid #d9d7d7;
  padding:5px;
}

.offlineOutletOfferDescriptionDetailstitle{
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}

.offlineOutletOfferDescriptionDetailstitledescription{
  font-size: 13px
}

.outerDivofflineOutletDetail{
  background:#f3f1f1;
  margin-top: 25px;
  padding:15px;
  border-radius:10px;
  
}

.innerDivOfflineOutletofferings{
  background:#fff;
  padding:15px;
  border-radius:10px;
  margin-bottom:15px;
}

.offeringTitle{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 6px;
}

.offeringDescription{
  font-size:13px;
}


/* search result css */
.iconDetails {
  margin-left:29%;
  float:left; 
  height:70px;
  width:70px;
  border-radius: 8px;	
 } 
 
 .container2 {
   width:100%;
   height:auto;
   padding:1%;
     float:left;
 }
 
 .left {float:left;width:45px;}
 .right {float:left;margin:0 0 0 5px;width:215px;}

 .searchOutletname{
    font-size: 16px;
    width: 160px;
    float: left;
    margin-top: 25px;
    margin-left: 52px;
    color:black;
 }

 .logininputbox{
    background-color: #413367!important;
    background-clip: padding-box;
    border: 2px solid #4e3664!important;
    color: #fff !important;;
 }

 .outletsearchinputbox{
  background-color: #413367!important;
  background-clip: padding-box;
  border: 0.1px solid #ced4da!important;
  color: #fff !important;;
}

.outletsearchinputbox:focus {
  outline: none!important;
}


 .loginbtn{
  background:#c356d1!important;
  border: 1px solid #c356d1!important;
 }

 .logininputbox::placeholder {
  color: #fff!important;
  opacity: 1; /* Firefox */
}

.logininputbox:focus {
  border: 1px solid #c356d1!important;
}


.otpinput{

  display: -webkit-box!important;
  margin-left:25%!important;
  margin-bottom: 15px!important;
}

.otpinputstyle{
  color: #c356d1!important;
  background-color: #413367!important;
}

.otpinputfocusstyle{
  border: 2px solid #c356d1!important;
}

input:focus{
  outline: none!important;
}


.otpresend{
  color:#fff;
  margin: 25px;
}

.usertype li {
  display: inline;
  padding: 13px;
  /* background: red; */
  margin: 2px;
  border-radius: 2px;
}

.active{
  background-color:#413367;
}

.activeoutlet{
  background-color:#413367;
  border-radius:6px;
}

/* gender button */

.box label {
  position:relative;
  cursor:pointer;
}
.box label input {
  display:none;
}
.box label span {
  position: relative;
    display: inline-block;
    margin: 4px 11px;
    font-size: 17px;
    background: #413367;
    border: 1px solid #444;
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
}
.box label input:checked ~ span {
  color:#fff;
  border: 1px solid #008eff;
}
.box label input:checked ~ span:before {
  content:'';
  width: 100%;
  height: 100%;
  position:absolute;
  top:0;
  left:0;
  background:#008eff;
  z-index:-1;
  filter: blur(10px);
}
.box label input:checked ~ span:after {
  content:'';
  width: 100%;
  height: 100%;
  position:absolute;
  top:0;
  left:0;
  background:#008eff;
  z-index:-1;
  filter: blur(15px);
}
/* .box label input:checked ~ span.yes {
  color:#62ff00;
  border-color:#62ff00;
  box-shadow: inset 0 0 10px #62ff00;
}

.box label input:checked ~ span.yes:before,
.box label input:checked ~ span.yes:after {
  background:#62ff00;
} */

.box label input:checked ~ span.no {
  /* color:#c356d1; */
  border-color:#c356d1;
  box-shadow: inset 0 0 10px #c356d1;
}

.box label input:checked ~ span.no:before,
.box label input:checked ~ span.no:after {
  background:#c356d1;
}

.btn:focus {
  outline: none !important;
  box-shadow: none!important;
}


a:hover {
  color:black;
  text-decoration:none!important;
}

.searchLocationResult{
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}

.activesearch{

  display:block;
}

.inactivesearch{

  display:none;
}

.searchboxlist{
  border-bottom-left-radius:4px!important;
  border-top-left-radius:4px!important;
  border-bottom-right-radius:0px!important;
  border-top-right-radius:0px!important;
}

.inlineserchbtn{
  border-bottom-left-radius:0px!important;
  border-top-left-radius:0px!important;
  border-bottom-right-radius:4px!important;
  border-top-right-radius:4px!important;
}


.ratingbtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #ffb543;
}
.off {
  color: #ccc;
}

.review :focus{
  border: 1px solid #c356d1!important;
  box-shadow: inset 0 0 10px #c356d1!important;
}

/* faq */
/* #main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 30px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
          box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #FFE472;
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #A541BB;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: #FFE472;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #FFE472;
  line-height: 30px;
  color: #222;
} */